define("discourse/plugins/dis-6do-tts/discourse/controllers/tts-index", ["exports", "@ember/object", "@ember/service", "discourse/lib/ajax", "@ember/controller", "discourse-common/utils/decorators", "discourse/plugins/dis-6do-tts/discourse/components/unlock-modal", "discourse/plugins/dis-6do-tts/discourse/components/buy-extra-modal", "discourse-i18n"], function (_exports, _object, _service, _ajax, _controller, _decorators, _unlockModal, _buyExtraModal, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _controller.default.extend((_dec = (0, _decorators.default)("selectedModelValue"), _dec2 = (0, _decorators.default)("model.tier.models"), _dec3 = (0, _decorators.default)("model.tier.models"), _dec4 = (0, _decorators.default)("model.tier.priority_models"), _dec5 = (0, _decorators.default)("model.tier"), (_obj = {
    modal: (0, _service.service)(),
    dialog: (0, _service.service)(),
    init() {
      this._super(...arguments);
      // this.set("availableModels", []);

      this.set("selectedModelValue", '');
      this.set('userInputText', '');
      this.set("isSubmitting", false);
      this.set("isSamplePlaying", false);
      const modelInterval = setInterval(() => {
        if (this.model) {
          const progressMins = document.querySelector('.progress-bar-usage[data-id="processing-mins-progress"]');
          const progressFiles = document.querySelector('.progress-bar-usage[data-id="processing-files-progress"]');
          if (this.model.tier) {
            if (!progressFiles || !progressFiles) return;
            const totalProgressMins = Math.min(this.model.usage.number_of_processed_mins / this.model.tier.number_of_processed_mins * 100, 100);
            const totalProgressFiles = Math.min(this.model.usage.number_of_processed_files / this.model.tier.number_of_processed_files * 100, 100);
            console.log(totalProgressFiles, totalProgressMins);
            progressFiles.style.setProperty("--progress", `${totalProgressFiles}%`);
            progressMins.style.setProperty("--progress", `${totalProgressMins}%`);
          }
          clearInterval(modelInterval);
        }
      }, 500);
    },
    throwAlert(detail) {
      return this.dialog.alert(detail);
    },
    async getTiers() {
      try {
        const tiers = await (0, _ajax.ajax)("/tts/admin/tiers");
        return tiers;
      } catch (err) {
        console.log(err);
        this.throwAlert(err.jqXHR.responseJSON.errors[0]);
      }
    },
    hasSample(selectedModelValue) {
      if (selectedModelValue) {
        const availableModels = JSON.parse(this.model.models);
        const findMatch = availableModels.find(o => o.short_name === selectedModelValue);
        if (findMatch && findMatch.sample_audio) {
          return findMatch.sample_audio;
        }
      }
      return false;
    },
    cleanModels(models) {
      let list = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      return models ? JSON.parse(models).map(raw => `${raw.locale} | ${raw.gender} | ${raw.name}`) : [];
    },
    availableModels(models) {
      return models ? JSON.parse(models).map(raw => ({
        value: raw.short_name,
        label: `${raw.locale} | ${raw.gender} | ${raw.name}`
      })) : [];
    },
    priorityOptions(models) {
      return models ? JSON.parse(models).map(raw => ({
        value: raw.short_name,
        label: `${raw.locale} | ${raw.gender} | ${raw.name}`
      })) : [];
    },
    isUserNotRestricted() {
      return !this.model.tier;
    },
    openUnlockModal() {
      return this.modal.show(_unlockModal.default);
    },
    openBuyExtra(type) {
      return this.modal.show(_buyExtraModal.default, {
        model: {
          type,
          cost: type == 'mins' ? this.model.tier.cost_per_additional_mins : this.model.tier.cost_per_additional_processed_files
        }
      });
    },
    onInputChange(e) {
      const maxLength = 1000;
      try {
        this.set("userInputText", e.target.value);
        var inputLength = e.target.value.length;
        var charCountSpan = document.querySelector('.tts-char-count');

        // Update the character count
        charCountSpan.textContent = inputLength + "/" + maxLength;

        // Change the color of the character count based on the input length
        if (inputLength < maxLength * 0.5) {
          charCountSpan.style.color = '#62dd57';
        } else if (inputLength < maxLength * 0.8) {
          charCountSpan.style.color = '#ddbc57';
        } else {
          charCountSpan.style.color = '#dd575b';
        }
      } catch (err) {
        console.log(err);
      }
    },
    async playSample() {
      if (this.hasSample) {
        if (!this.isSamplePlaying) {
          this.set("isSamplePlaying", new Audio(this.hasSample));
          this.isSamplePlaying.play();
          this.isSamplePlaying.addEventListener("ended", () => {
            this.set("isSamplePlaying", false);
          });
        } else {
          this.isSamplePlaying.pause();
          this.set("isSamplePlaying", false);
        }
      }
    },
    async submitJob() {
      if (!this.selectedModelValue.length) {
        return this.throwAlert(_discourseI18n.default.t("dis_6do_tts.generic_errors.select_model"));
      } else if (this.userInputText.length < 4) {
        return this.throwAlert(_discourseI18n.default.t("dis_6do_tts.generic_errors.select_model"));
      }
      this.set("isSubmitting", true);
      try {
        const chosenModel = JSON.parse(this.model.models).find(model => model.short_name === this.selectedModelValue[0]);
        let data = await (0, _ajax.ajax)('/tts/submit', {
          type: "POST",
          data: {
            input_text: this.userInputText,
            model_name: this.selectedModelValue,
            gender: chosenModel ? chosenModel.gender : null
          }
        });
        window.location.reload();
      } catch (err) {
        this.throwAlert(err.jqXHR.responseJSON.errors[0]);
      }
      this.set("isSubmitting", false);
    }
  }, (_applyDecoratedDescriptor(_obj, "hasSample", [_dec], Object.getOwnPropertyDescriptor(_obj, "hasSample"), _obj), _applyDecoratedDescriptor(_obj, "cleanModels", [_dec2], Object.getOwnPropertyDescriptor(_obj, "cleanModels"), _obj), _applyDecoratedDescriptor(_obj, "availableModels", [_dec3], Object.getOwnPropertyDescriptor(_obj, "availableModels"), _obj), _applyDecoratedDescriptor(_obj, "priorityOptions", [_dec4], Object.getOwnPropertyDescriptor(_obj, "priorityOptions"), _obj), _applyDecoratedDescriptor(_obj, "isUserNotRestricted", [_dec5], Object.getOwnPropertyDescriptor(_obj, "isUserNotRestricted"), _obj), _applyDecoratedDescriptor(_obj, "openUnlockModal", [_object.action], Object.getOwnPropertyDescriptor(_obj, "openUnlockModal"), _obj), _applyDecoratedDescriptor(_obj, "openBuyExtra", [_object.action], Object.getOwnPropertyDescriptor(_obj, "openBuyExtra"), _obj), _applyDecoratedDescriptor(_obj, "onInputChange", [_object.action], Object.getOwnPropertyDescriptor(_obj, "onInputChange"), _obj), _applyDecoratedDescriptor(_obj, "playSample", [_object.action], Object.getOwnPropertyDescriptor(_obj, "playSample"), _obj), _applyDecoratedDescriptor(_obj, "submitJob", [_object.action], Object.getOwnPropertyDescriptor(_obj, "submitJob"), _obj)), _obj)));
});