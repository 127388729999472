define("discourse/plugins/dis-6do-tts/discourse/controllers/admin-plugins-dis-6do-tts", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse/plugins/dis-6do-tts/discourse/components/update-tier-modal", "discourse/plugins/dis-6do-tts/discourse/components/create-tier-modal"], function (_exports, _controller, _object, _service, _updateTierModal, _createTierModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _controller.default.extend((_obj = {
    modal: (0, _service.service)(),
    init() {
      this._super(...arguments);
      this.set('availableTiers', [{
        value: 'tier_1',
        label: 'Tier 1'
      }, {
        value: 'tier_2',
        label: 'Tier 2'
      }, {
        value: 'tier_3',
        label: 'Tier 3'
      }]);
      this.set('selectedTier', 'tier_1');
    },
    openUpdateTierModal() {
      return this.modal.show(_updateTierModal.default);
    },
    openCreateTierModal() {
      return this.modal.show(_createTierModal.default);
    }
  }, (_applyDecoratedDescriptor(_obj, "openUpdateTierModal", [_object.action], Object.getOwnPropertyDescriptor(_obj, "openUpdateTierModal"), _obj), _applyDecoratedDescriptor(_obj, "openCreateTierModal", [_object.action], Object.getOwnPropertyDescriptor(_obj, "openCreateTierModal"), _obj)), _obj));
});